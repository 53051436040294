<main role="main" style="background-color: #ccecf9; min-height: 600px">
  <div class="container">
    <h1 class="mt-2">
      About NutriPilot
    </h1>
    <p>
      Welcome to NutriPilot, your personal guide to better health and wellness.
    </p>
    <p>

      At NutriPilot, we believe that navigating the world of supplements doesn’t have to be overwhelming. That’s why we’ve created an AI-powered platform designed to simplify the process, offering personalized recommendations based on your unique needs and goals.

    </p>
    <p>

      Whether you’re looking to boost your energy, improve your sleep, or support your overall well-being, NutriPilot is here to help. Our intelligent system analyzes your preferences and health objectives to connect you with trusted, high-quality supplements that fit seamlessly into your lifestyle.
      We partner only with reputable brands to ensure you get products you can trust. With NutriPilot, you’ll find clear, reliable advice and product suggestions – all in one place.
      Join us on a journey towards better health, powered by innovation and simplicity.

    </p>
    <p>

      Your wellness is our mission. Let NutriPilot guide you there.
    </p>
    </div>
</main>
