<header>
  <div class="collapse bg-dark" id="navbarHeader">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 col-md-7 py-4">
          <h4 class="text-white">About</h4>
          <p class="text-muted">Add some information about the album below, the author, or any other background context. Make it a few sentences long so folks can pick up some informative tidbits. Then, link them off to some social networking sites or contact information.</p>
        </div>
        <div class="col-sm-4 offset-md-1 py-4">
          <h4 class="text-white">Contact</h4>
          <ul class="list-unstyled">
            <li><a href="#" class="text-white">Follow on Twitter</a></li>
            <li><a href="#" class="text-white">Like on Facebook</a></li>
            <li><a href="#" class="text-white">Email me</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="navbar navbar-light bg-light box-shadow">
    <div class="container d-flex justify-content-between">
      <a href="#" class="">
        <img src="/Content/images/logo.png" class="img-fluid" style="height: 40px" />
      </a>
      <div ngbDropdown class="d-inline-block">
        <button type="button" class="btn btn-sm btn-outline-dark" id="dropdownBasic1" ngbDropdownToggle>
          <img [attr.src]="'/Content/images/' + locale + '.svg'" width="20" />
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem (click)="gotoSite('en')"><img src="/Content/images/en.svg" width="20" /> Europe</button>
          <button ngbDropdownItem (click)="gotoSite('sv')"><img src="/Content/images/sv.svg" width="20" /> Sweden</button>
          <button ngbDropdownItem (click)="gotoSite('en-us')"><img src="/Content/images/en-us.svg" width="20" /> USA</button>
        </div>
      </div>
    </div>
  </div>
</header>
<router-outlet></router-outlet>
<section style="background: white;">
  <div class="container g-4 py-5 ">
    <div class="row row-cols-1 row-cols-lg-3">
      <div class="col d-flex align-items-start">
        <div class="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
          <i class="fad fa-heartbeat"></i>
        </div>
        <div>
          <h3 class="fs-4 text-body-emphasis" i18n>
            Discover supplements tailored to your needs.
          </h3>
          <p i18n>
            Our AI analyzes your preferences and health goals to provide product suggestions that truly work for you.
          </p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <div class="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
          <i class="fad fa-capsules"></i>
        </div>
        <div>
          <h3 class="fs-4 text-body-emphasis" i18n>
            High-quality supplements you can rely on.
          </h3>
          <p i18n>
            We partner with reputable brands to ensure every recommendation meets the highest standards of safety and effectiveness.
          </p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <div class="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
          <i class="fad fa-head-side-brain"></i>
        </div>
        <div>
          <h3 class="fs-4 text-body-emphasis" i18n>
            Your health journey simplified.
          </h3>
          <p i18n>
            Get quick, easy, and personalized advice anytime, anywhere – all in just a few clicks.
          </p>
        </div>
      </div>
    </div>
    <div class="card  text-bg-light  my-5">
      <div class="card-body" i18n>
        This service is powered by AI and is intended for informational purposes only. The recommendations provided are not a substitute for professional medical advice, diagnosis, or treatment. Always consult a doctor or other qualified healthcare professional with any questions or concerns about your health. Use of this service is at your own risk.
      </div>
    </div>
  </div>
</section>
<footer style="background-color: #ccecf9;" class="py-4">
  <div class="d-flex flex-wrap justify-content-between align-items-center container">
    <p class="col-md-4 mb-0 text-body-secondary">© 2024 Bitopia AB</p>


    <ul class="nav col-md-4 justify-content-end">
      <li class="nav-item"><a href="/" class="nav-link px-2 text-body-secondary">Home</a></li>
      <li class="nav-item"><a routerLink="/about" class="nav-link px-2 text-body-secondary">About</a></li>
    </ul>
  </div>
</footer>
