import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'item-card',
  templateUrl: './item-card.component.html',
  styles: [
  ],
  animations: [ 
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }), 
        animate('500ms ease-in', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class ItemCardComponent {

  @Input() item: any;
  constructor() { }

}
