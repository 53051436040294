import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styles: ``
})
export class StartComponent implements OnInit, AfterViewInit {
  public messages: ChatMessage[] = [];
  public activeAnswer: ChatMessage = null;
  public question: string = '';
  public threadid: any = null;
  public runid: any = null;
  public chatHeight?: number = null;
  public locale: string = null;
  readonly apiuri = '/api/assistant';
  public startText = $localize`How can I help you find the right supplement for your needs? Is there a specific vitamin or nutrient you\'re interested in?`;
  @ViewChild('chatarea') chatarea: ElementRef;
  firstMessage: ChatMessage =
    {
      'message': '', 'from': 'ai', tags: [$localize`Tired`, $localize`Loose weight`, $localize`Build Muscles`, $localize`Thin haired`], items: null
    }

  constructor(private http: HttpClient, private cdr: ChangeDetectorRef, private router: Router, private titleService: Title) {

  }

  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    this.titleService.setTitle($localize`NutriPilot - AI-driven nutrition advice`);

    let enteredText = '';
    let index = 0;
    const intervalId = setInterval(() => {
      if (index < this.startText.length) {
        enteredText += this.startText[index];
        this.firstMessage.message = enteredText;
        index++;
      } else {
        clearInterval(intervalId); // Stop when the text is fully printed
      }
    }, 10);
  }




  scrollToBottom() {
    try {
      this.chatarea.nativeElement.scrollTop = this.chatarea.nativeElement.scrollHeight;
    } catch (err) {
      console.log('scroll error: ' + err);
    }
  }

  submitQuestion() {

    var message = new ChatMessage();
    message.from = 'me';
    message.message = this.question;

    this.messages.push(message);

    let sendQuestion = this.question;
    this.question = '';
    if (this.threadid == null) {
      // First time
      this.chatHeight = this.chatarea.nativeElement.scrollHeight + 50;
      setTimeout(() => {
        this.chatHeight = window.innerHeight - 120;
      });
      let messages = [
        { role: 'assistant', content: this.startText },
        { role: 'user', content: sendQuestion }
      ];
      this.http.post(this.apiuri + '/thread', messages).subscribe(async (thread: any) => {
        this.threadid = thread.id;
        this.updateChat();
      });
    }
    else {
      this.http.post<any>(`${this.apiuri}/thread/${this.threadid}/messages`, { content: sendQuestion }).subscribe(data => {
        let runid: string;
        this.updateChat();
      })
    }
    this.scrollToBottom();
  }

  selectTag(tag: string) {
    this.question = tag;
    this.submitQuestion();
  }

  buffer = '';
  async getFullLine(reader: ReadableStreamDefaultReader<string>) {
    while (true) {
      const { value, done } = await reader.read();
      if (done && !this.buffer) {
        return null;
      };
      if (value) this.buffer += value;

      let splitIndex;
      while ((splitIndex = this.buffer.indexOf('\n\n')) > -1) {
        const line = this.buffer.substring(0, splitIndex);
        this.buffer = this.buffer.substring(splitIndex + 2);
        return line ?? '';
      }
    }
  }

  async updateChat(runtype: string = 'Normal', data?: string, runid?: string, callid?: string) {
    this.activeAnswer = this.activeAnswer || new ChatMessage();
    this.activeAnswer.from = 'ai';
    let sendOnEnd = true;
    this.buffer = '';
    const response = await fetch(`${this.apiuri}/thread/${this.threadid}/runs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        RunType: runtype,
        RunId: runid,
        CallId: callid,
        Data: data
      })
    });
    if (!response.body) return;
    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .getReader();

    while (true) {
      let value = await this.getFullLine(reader);
      if (value == null) {
        break;
      }

      try {
        if (value == '' || value == null)
          continue;
        let json = JSON.parse(value.substring(6));
        if (json.type === "delta") {
          this.activeAnswer.message += json.content;
          this.cdr.detectChanges();
        } else if (json.type === "call_function") {
          let search = JSON.parse(json.functionArgs).searchword;
          sendOnEnd = false;
          this.http.get('/api/item/search?query=' + search).subscribe((data: any) => {
            this.activeAnswer.items = data;
            // join names to list:

            this.updateChat('ToolSubmit', JSON.stringify(data.map((x: any) => x.name)), this.runid, json.callid);
          });
          break;
        }
        else if (json.type === "running") {
          this.runid = json.runid;
        }
        else if (json.type === "completed") {
          if (sendOnEnd) {
            this.activeAnswer.message = json.content;
            if (json.tags) {
              this.activeAnswer.tags = json.tags;
            }
            this.messages.push(this.activeAnswer);
            this.activeAnswer = null;
          }
          this.scrollToBottom();
        }
      } catch (e) {
        console.log(`error: ${e}`);
      }
      this.scrollToBottom();
    }
  }
}
export class ChatMessage {
  constructor() { }
  public from: string;
  public message: string = '';
  public items: any[];
  tags: any[];
}
