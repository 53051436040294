import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { ItemCardComponent } from './item-card/item-card.component';
import { MessageComponent } from './message/message.component';
import { registerLocaleData } from '@angular/common';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import localeSv from '@angular/common/locales/sv';
import localeSvExtra from '@angular/common/locales/extra/sv';
import { StartComponent } from './start/start.component';
import { AboutComponent } from './about/about.component';

registerLocaleData(localeSv, 'sv-SE', localeSvExtra);

const routes: Routes = [
  { path: '', component: StartComponent },
  { path: 'about', component: AboutComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    ItemCardComponent,
    MessageComponent,
    StartComponent
  ],
  bootstrap: [AppComponent],
  imports:
    [
      BrowserModule,
      FormsModule,
      MarkdownModule.forRoot(),
      NgbDropdown,
      NgbDropdownMenu,
      NgbDropdownToggle,
      NgbDropdownItem,
      RouterModule.forRoot(routes) // Add RouterModule here
    ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: LOCALE_ID,
      useValue: 'sv-SE'
    }
  ]
})
export class AppModule { }
