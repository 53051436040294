import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatMessage } from '../start/start.component';

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styles: [
  ]
})
export class MessageComponent {

  @Input() message: ChatMessage;
  @Input() isfocus: boolean;
  @Input() iswriting: boolean;
  @Input() showitems: boolean;
  @Output() onSelectTag = new EventEmitter<string>();;

  selectTag(tag: string) {
    this.onSelectTag.emit(tag);
  }
}
