<main role="main" style="background-color: #ccecf9; min-height: 600px">
  <div class="container">
    <div #chatarea class="thread py-5 chatarea" [class.bigmode]="messages.length > 0 || activeAnswer != null" [style.height]="chatHeight ? chatHeight + 'px' : null">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="frontnote">
            <i class="fad fa-stars fa-3x float-end"></i>
            <h3 i18n>Welcome to NutriPilot!</h3>
            <p i18n>Talk to our assistant about vitamins. supplements and everything nutrition.</p>
          </div>
        </div>
        <div class="col-12 col-md-8 order-md-first first">
          <message [message]="firstMessage" [isfocus]="true" (onSelectTag)="selectTag($event)"></message>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ng-container *ngFor="let message of messages; let last = last">
            <message [message]="message" [isfocus]="last" (onSelectTag)="selectTag($event)" [showitems]="true"></message>
          </ng-container>
          <message [message]="activeAnswer" *ngIf="activeAnswer" [isfocus]="true" [iswriting]="true"></message>
        </div>
      </div>
    </div>
    <form class="pt-1 pb-5" (submit)="submitQuestion()">
      <div class="custom-search">
        <input autofocus type="text" class="custom-search-input" autocomplete="off" [(ngModel)]="question" name="question" i18n-placeholder placeholder="Write your question..." />
        <button class="custom-search-button" type="submit" [disabled]="question?.length == 0"><i class="fad fa-paper-plane"></i></button>
      </div>
    </form>

  </div>
</main>
