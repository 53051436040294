<div class="thread-input shadow" [class.me]="message.from == 'me'" [class.ai]="message.from == 'ai'" [class.old]="!isfocus">
  <div class="thread-image shadow" *ngIf="message.from == 'ai'">
    <img src="/content/images/pharmacist.png" class="img-fluid" alt="Cinque Terre">
  </div>
  <div class="thread-content">
    <div markdown
         [data]="message.message"
         [inline]="true">
    </div>
    <div class="spinner-grow text-secondary" *ngIf="iswriting && message.message == ''">
    </div>
    <div class="row row-cols-2 row-cols-md-4 g-4" *ngIf="message.items && message.items.length > 0 && showitems">
      <div class="col" *ngFor="let item of message.items">
        <item-card [item]="item"></item-card>
      </div>
    </div>
    <div *ngIf="message.tags" class="mt-1">
      <button (click)="selectTag(tag)" class="btn btn-outline-dark rounded-pill m-1" *ngFor="let tag of message.tags">{{tag}}</button>
    </div>
  </div>
</div>
